import React from "react";
import PropTypes from "prop-types";
import { useStaticQuery, graphql } from "gatsby";
import Box from "common/components/Box";
import Text from "common/components/Text";
import Heading from "common/components/Heading";
import Button from "common/components/Button";
import {
  Accordion,
  AccordionItem,
  AccordionTitle,
  AccordionBody,
  IconWrapper,
  OpenIcon,
  CloseIcon,
} from "common/components/Accordion";
import Container from "common/components/UI/Container";
import { Icon } from "react-icons-kit";
import { plus } from "react-icons-kit/entypo/plus";
import { minus } from "react-icons-kit/entypo/minus";

import FaqWrapper from "./faq.style";

const FaqSection = ({
  sectionWrapper,
  row,
  col,
  secTitleWrapper,
  secHeading,
  secText,
  title,
  description,
  buttonWrapper,
  button,
  lang = "He",
}) => {
  const textHead = {
    bigTitle: { He: "שאלות נפוצות", En: "FREQUENTLY ASKED QUESTION" },
    smallTitle: {
      He: "הנה כמה תשובות לשאלות אלו",
      En: "There are some answers",
    },
  };
  const FAQData = [
    {
      expend: true,
      title: {
        He: "מי יוצר את הסיורים העצמאיים באפליקציה?",
        En: "Who creates the independent tours on the application ?",
      },
      description: {
        He: "מדריכי טיולים, אנשי תוכן ובלוגרים מקצועיים ובעלי ניסיון רב שנבחרו בקפידה דואגים שלכם יהיה את התוכן המעניין ביותר בכל סיור עצמאי",
        En: "Tour guides, bloggers and professionals who create content ,as well as individuals that have long term experience that were chosen carefully to make sure you will receive the most interesting content throughout the tour",
      },
    },
    {
      expend: true,
      title: {
        He: "מה זה סיור עצמאי בDguide?",
        En: "What is a Self-Guided tour on Dguide ?",
      },
      description: {
        He: "הסיור העצמאי ינווט אתכם למקומות, האטרקציות והפינות הנסתרות ביותר על פי קטגוריית עניין והסיור שבחרתם. את תוכן המדריך תפגשו בכל נקודת עניין בוידאו ,אודיו, טקסט תמונות משחקי טריוויה ועוד בזמן שלכם ובקצב שלכם :)",
        En: "The Selg-Guided tour will navigate you to places, attractions and hidden corners depending on the category and the tour that you have chosen. You will receive content from the tour-guide at every point in the video, audio, text photos and trivia games. and all that at your own time and pace. ",
      },
    },
    {
      expend: true,
      title: {
        He: "הייכן רואים ורוכשים את הסיורים העצמאיים?",
        En: "Where can you view and purchase the independent tours?",
      },
      description: {
        He: "עלייכם להוריד את האפליקציה שלנו (לינק מופיע מעלה) לנייד. באפליקציה תוכלו לבחור סיורים על פי קטגוריות סינון מתקדמות. לכל סיור ישנו טריילר ",
        En: "Download our application (link on top) to your phone. On the app you can choose tours from the advanced filtered categories. and Every tour has a trailer.",
      },
    },
    {
      expend: true,
      title: {
        He: "איך יוצרים סיור עצמאי?",
        En: "How do you create an independent tour ?",
      },
      description: {
        He: "נכנסים לפלטפורמת יצירת הסיורים, יוצרים סיור עצמאי ייחודי ומעניין. לאחר מכן 'בודק התוכן' שלנו יצור איתכם קשר.",
        En: "You enter the tour creating platform, and create an individual unique and interesting tour. After that our “content checker” will contact you.",
      },
    },
  ];
  return (
    <Box
      {...sectionWrapper}
      id="faq_section"
      style={{ direction: lang === "He" ? "rtl" : "ltr" }}
    >
      <Container>
        <Box {...secTitleWrapper}>
          <Text {...secText} content={textHead["bigTitle"][lang]} />
          <Heading {...secHeading} content={textHead["smallTitle"][lang]} />
        </Box>
        <Box {...row}>
          <Box {...col}>
            <FaqWrapper>
              <Accordion>
                <>
                  {/*  {{ FAQData.map((accordionItem, index) => (
                    <AccordionItem
                      className="accordion_item"
                      key={`accordion-${index}`}
                      expanded={accordionItem.expend}
                    >
                      <>
                        <AccordionTitle className="accordion_title">
                          <>
                            <Heading {...title} content={accordionItem.title[lang]} />
                            <IconWrapper>
                              <OpenIcon>
                                <Icon icon={minus} size={18} />
                              </OpenIcon>
                              <CloseIcon>
                                <Icon icon={plus} size={18} />
                              </CloseIcon>
                            </IconWrapper>
                          </>
                        </AccordionTitle>
                        <AccordionBody className="accordion_body">
                          <Text
                            {...description}
                            content={accordionItem.description[lang]}
                          />
                        </AccordionBody>
                      </>
                    </AccordionItem>
                  ))} } */}
                  <AccordionItem
                    className="accordion_item"
                    key={`accordion-${1}`}
                    expanded={FAQData[0].expend}
                  >
                    <>
                      <AccordionTitle className="accordion_title">
                        <>
                          <Heading
                            {...title}
                            content={FAQData[0].title[lang]}
                          />
                          <IconWrapper>
                            <OpenIcon>
                              <Icon icon={minus} size={18} />
                            </OpenIcon>
                            <CloseIcon>
                              <Icon icon={plus} size={18} />
                            </CloseIcon>
                          </IconWrapper>
                        </>
                      </AccordionTitle>
                      <AccordionBody className="accordion_body">
                        <Text
                          {...description}
                          content={FAQData[0].description[lang]}
                        />
                      </AccordionBody>
                    </>
                  </AccordionItem>
                  <AccordionItem
                    className="accordion_item"
                    key={`accordion-${2}`}
                    expanded={FAQData[1].expend}
                  >
                    <>
                      <AccordionTitle className="accordion_title">
                        <>
                          <Heading
                            {...title}
                            content={FAQData[1].title[lang]}
                          />
                          <IconWrapper>
                            <OpenIcon>
                              <Icon icon={minus} size={18} />
                            </OpenIcon>
                            <CloseIcon>
                              <Icon icon={plus} size={18} />
                            </CloseIcon>
                          </IconWrapper>
                        </>
                      </AccordionTitle>
                      <AccordionBody className="accordion_body">
                        <Text
                          {...description}
                          content={FAQData[1].description[lang]}
                        />
                      </AccordionBody>
                    </>
                  </AccordionItem>
                  <AccordionItem
                    className="accordion_item"
                    key={`accordion-${3}`}
                    expanded={FAQData[2].expend}
                  >
                    <>
                      <AccordionTitle className="accordion_title">
                        <>
                          <Heading
                            {...title}
                            content={FAQData[2].title[lang]}
                          />
                          <IconWrapper>
                            <OpenIcon>
                              <Icon icon={minus} size={18} />
                            </OpenIcon>
                            <CloseIcon>
                              <Icon icon={plus} size={18} />
                            </CloseIcon>
                          </IconWrapper>
                        </>
                      </AccordionTitle>
                      <AccordionBody className="accordion_body">
                        <Text
                          {...description}
                          content={FAQData[2].description[lang]}
                        />
                      </AccordionBody>
                    </>
                  </AccordionItem>
                  <AccordionItem
                    className="accordion_item"
                    key={`accordion-${4}`}
                    expanded={FAQData[3].expend}
                  >
                    <>
                      <AccordionTitle className="accordion_title">
                        <>
                          <Heading
                            {...title}
                            content={FAQData[3].title[lang]}
                          />
                          <IconWrapper>
                            <OpenIcon>
                              <Icon icon={minus} size={18} />
                            </OpenIcon>
                            <CloseIcon>
                              <Icon icon={plus} size={18} />
                            </CloseIcon>
                          </IconWrapper>
                        </>
                      </AccordionTitle>
                      <AccordionBody className="accordion_body">
                        <Text
                          {...description}
                          content={FAQData[3].description[lang]}
                        />
                      </AccordionBody>
                    </>
                  </AccordionItem>
                </>
              </Accordion>
            </FaqWrapper>
            <Box {...buttonWrapper}>
              {/* <a href="#1">
                <Button {...button} title="EXPLORE FORUM" />
              </a> */}
            </Box>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

FaqSection.propTypes = {
  sectionWrapper: PropTypes.object,
  secTitleWrapper: PropTypes.object,
  row: PropTypes.object,
  col: PropTypes.object,
  secHeading: PropTypes.object,
  secText: PropTypes.object,
  title: PropTypes.object,
  description: PropTypes.object,
  buttonWrapper: PropTypes.object,
  button: PropTypes.object,
};

FaqSection.defaultProps = {
  sectionWrapper: {
    as: "section",
    pt: ["20px", "30px", "50px", "50px"],
    pb: ["60px", "80px", "80px", "80px"],
  },
  secTitleWrapper: {
    mb: ["55px", "65px"],
  },
  secText: {
    as: "span",
    display: "block",
    textAlign: "center",
    fontSize: "14px",
    letterSpacing: "0.15em",
    fontWeight: "700",
    color: "#1c2338",
    mb: "5px",
  },
  secHeading: {
    textAlign: "center",
    fontSize: ["20px", "24px"],
    fontWeight: "500",
    color: "#0f2137",
    letterSpacing: "-0.025em",
    mb: "0",
    lineHeight: "1.67",
  },
  row: {
    flexBox: true,
    flexWrap: "wrap",
    justifyContent: "center",
  },
  col: {
    width: ["100%", "100%", "75%", "75%"],
  },
  title: {
    fontSize: ["16px", "19px"],
    fontWeight: "400",
    color: "headingColor",
    letterSpacing: "-0.025em",
    mb: 0,
  },
  description: {
    fontSize: "15px",
    color: "textColor",
    lineHeight: "1.75",
    mb: 0,
  },
  buttonWrapper: {
    mt: "50px",
    flexBox: true,
    justifyContent: "center",
  },
  button: {
    type: "button",
    fontSize: "14px",
    fontWeight: "600",
    borderRadius: "4px",
    pl: ["22px"],
    pr: ["22px"],
    colors: "secondaryWithBg",
    minWidth: "150px",
  },
};

export default FaqSection;
