import React from "react";
import PropTypes from "prop-types";
import { useStaticQuery, graphql } from "gatsby";
import Box from "common/components/Box";
import Text from "common/components/Text";
import Heading from "common/components/Heading";
import Fade from "react-reveal/Fade";
import FeatureBlock from "common/components/FeatureBlock";
import Container from "common/components/UI/Container";
import FeatureSectionWrapper from "./feature.style";
import selfGuideImg from "../../images/selfGuide.svg";
import navigImg from "../../images/navigation.svg";
import contentImg from "../../images/content.svg";
import feature1 from "../../images/feature1.png";
import feature2 from "../../images/feature2.png";
import feature3 from "../../images/feature3.png";

const FeatureSection = ({
  row,
  col,
  secTitleWrapper,
  secTitle,
  secDescription,
  featureTitle,
  featureDescription,
  iconStyle,
  contentStyle,
  blockWrapperStyle,
  lang = "He",
}) => {
  const Data = useStaticQuery(graphql`
    query {
      rideJson {
        Features {
          id
          title
          description
          img {
            publicURL
          }
        }
      }
    }
  `);

  const textLang = {
    bigHeader: {
      He: "האפליקציה שהולכת לשנות את עולם הטיולים שלך",
      En: "An app that will change the way you travel",
    },
    smallHeader: {
      He: "מי אנחנו?",
      En: "Who we are?",
    },
    actionBut: { He: "התחל עכשיו", En: "Start Now" },
    videoBut: { He: "צפה בוידיאו", En: "Watch Trailer" },
  };
  const features = [
    {
      id: 1,
      title: { He: "סיורים עצמאיים", En: "Self-Guided tours" },
      description: {
        He: "אפליקציית DGuide נותנת שירותי סיור עצמאי המתאימים לצרכי המטייל ללא נוכחות פיזית של מדריך. סיוריי היסטוריה, קולינרייה, דת, חיי לילה ועוד נבנים על ידי מדריכים מקומיים דרך פלטפורמת המדריכים החדשנית והטכנולוגית ביותר בשוק התיירות העולמי. ",
        En: "DGuide provides independent tour services that suit the needs of the traveler without having a physical guide. History, culinary, religious, and nightlife tours are set up by local guides through the newest tech platforms available today for tour guides.",
      },
    },
    {
      id: 2,
      title: { He: "נווטו בקצב שלכם", En: "Navigate at your own pace" },
      description: {
        He: "בין תחנות הסיור שנבחר תוכלו לנווט דרך האפליקציה שתדאג שלא תפספסו את היעדים הפופולריים בטיול והכי חשוב בקצב שלכם וללא נוכחות פיזית של המדריך.",
        En: "Navigate at your own pace between the different touring stations and the app will make sure you don’t miss the most popular sites, most importantly -at your own pace and without the physical presence of a tour-guide.",
      },
    },
    {
      id: 3,
      title: {
        He: "המדריך שלך, בנייד שלך",
        En: "Your guide, on your phone",
      },
      description: {
        He: "בכל אחת מהתחנות יחכה לכם המדריך עם תוכן ייחודי שדרכו תגלו סיפורים מעניינים, פינות נסתרות, ריחות וטעמים חדשים. טעימות קולינריות, אטרקציות ועוד ישתלבו בחלק מהסיורים ועל פי רצונכם.",
        En: "In each of the stations, the guide will be waiting for you with unique content through which you will discover interesting stories, hidden corners, new smells and tastes. Authentic shops, attractions and more will be included in some of the tours and according to your wishes.",
      },
    },
  ];
  return (
    <FeatureSectionWrapper
      id="feature_section"
      style={{ direction: lang === "He" ? "rtl" : "ltr" }}
    >
      <Container noGutter mobileGutter width="1200px" className="container">
        <Box {...secTitleWrapper}>
          <Heading {...secTitle} content={textLang["smallHeader"][lang]} />
          {/* <Text {...secDescription} content={textLang["smallHeader"][lang]} /> */}
        </Box>
        <Box className="row" {...row}>
          {/* {Data.rideJson.Features.map((feature, index) => (
            <Box className="col" {...col} key={index}>
              <Fade up>
                <FeatureBlock
                  icon={<img src={feature.img.publicURL} alt={feature.title} />}
                  wrapperStyle={blockWrapperStyle}
                  iconStyle={iconStyle}
                  contentStyle={contentStyle}
                  title={<Heading content={feature.title} {...featureTitle} />}
                  description={
                    <Text
                      content={feature.description}
                      {...featureDescription}
                    />
                  }
                  className="saasFeature"
                />
              </Fade>
            </Box>
          ))} */}
          <Box className="col" {...col} key={1}>
            <Fade up>
              <FeatureBlock
                icon={
                  <img
                    style={{ height: "180px" }}
                    src={feature1}
                    alt={features[0].title[lang]}
                  />
                }
                wrapperStyle={blockWrapperStyle}
                iconStyle={iconStyle}
                contentStyle={contentStyle}
                title={
                  <Heading
                    content={features[0].title[lang]}
                    {...featureTitle}
                  />
                }
                description={
                  <Text
                    content={features[0].description[lang]}
                    {...featureDescription}
                  />
                }
                className="saasFeature"
              />
            </Fade>
          </Box>
          <Box className="col" {...col} key={2}>
            <Fade up>
              <FeatureBlock
                icon={
                  <img
                    style={{ height: "180px" }}
                    src={feature2}
                    alt={features[1].title[lang]}
                  />
                }
                wrapperStyle={blockWrapperStyle}
                iconStyle={iconStyle}
                contentStyle={contentStyle}
                title={
                  <Heading
                    content={features[1].title[lang]}
                    {...featureTitle}
                  />
                }
                description={
                  <Text
                    content={features[1].description[lang]}
                    {...featureDescription}
                  />
                }
                className="saasFeature"
              />
            </Fade>
          </Box>
          <Box className="col" {...col} key={3}>
            <Fade up>
              <FeatureBlock
                icon={
                  <img
                    style={{ height: "180px" }}
                    src={feature3}
                    alt={features[2].title[lang]}
                  />
                }
                wrapperStyle={blockWrapperStyle}
                iconStyle={iconStyle}
                contentStyle={contentStyle}
                title={
                  <Heading
                    content={features[2].title[lang]}
                    {...featureTitle}
                  />
                }
                description={
                  <Text
                    content={features[2].description[lang]}
                    {...featureDescription}
                  />
                }
                className="saasFeature"
              />
            </Fade>
          </Box>
        </Box>
      </Container>
    </FeatureSectionWrapper>
  );
};

// FeatureSection style props
FeatureSection.propTypes = {
  secTitleWrapper: PropTypes.object,
  row: PropTypes.object,
  col: PropTypes.object,
  secTitle: PropTypes.object,
  secDescription: PropTypes.object,
  featureTitle: PropTypes.object,
  featureDescription: PropTypes.object,
};

// FeatureSection default style
FeatureSection.defaultProps = {
  // section header default style
  secTitleWrapper: {
    mb: ["65px", "65px", "80px", "90px", "90px"],
  },
  secTitle: {
    fontSize: ["22px", "26px", "26px", "30px", "36px"],
    mr: "40px",
    ml: "40px",
    fontWeight: "600",
    color: "#15172C",
    lineHeight: "1.34",
    mb: ["15px", "18px", "18px", "20px", "30px"],
    textAlign: "center",
    fontFamily: "Poppins",
  },
  secDescription: {
    fontSize: ["15px", "16px"],
    fontWeight: "400",
    color: "#15172C",
    lineHeight: "1.5",
    mb: "0",
    textAlign: "center",
    width: "300px",
    maxWidth: "100%",
    ml: "auto",
    mr: "auto",
    fontFamily: "Lato",
  },
  // feature row default style
  row: {
    flexBox: true,
    flexWrap: "wrap",
  },
  // feature col default style
  col: {
    width: [1, 1 / 2, 1 / 3, 1 / 3],
  },
  // feature block wrapper default style
  blockWrapperStyle: {
    p: ["30px", "20px", "20px", "20px"],
  },
  // feature icon default style

  // feature content default style
  contentStyle: {
    textAlign: "center",
    mt: ["30px", "30px"],
  },
  // feature title default style
  featureTitle: {
    fontSize: ["15px", "16px"],
    lineHeight: "1.5",
    fontWeight: "600",
    color: "#15172C",
    textAlign: "center",
    fontFamily: "Poppins",
  },
  // feature description default style
  featureDescription: {
    lineHeight: ["28px", "32px", "32px", "32px", "32px"],
    mt: ["15px", "15px", "15px", "15px", "15px"],
    maxWidth: ["100%", "100%", "100%", "270px", "270px"],
    textAlign: ["center", "center"],
    fontSize: ["15px", "16px"],
    fontWeight: "400",
    color: "#15172C",
    fontFamily: "Lato",
  },
};

export default FeatureSection;
