import styled from "styled-components";
import { themeGet } from "@styled-system/theme-get";

export const SelectWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 104px;
  margin-top: 45px;
  position: relative;
  min-height: 24px;
  @media only screen and (max-width: 1440px) {
    margin-top: 40px;
  }
  @media only screen and (max-width: 1360px) {
    margin-top: 30px;
  }
  @media only screen and (max-width: 991px) {
    margin-top: 20px;
  }

  select {
    border: 0;
    outline: none;
    background: transparent;
    appearance: none;
    color: ${themeGet("colors.select", "#525F7F")};
    font-size: 18px;
    cursor: pointer;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 24px;
    z-index: 1;
    @media only screen and (max-width: 1440px) {
      font-size: 16px;
    }
    @media only screen and (max-width: 1360px) {
      font-size: 15px;
    }
  }

  svg {
    position: absolute;
    top: 1px;
    right: 10px;
    path {
      fill: ${themeGet("colors.secondaryText", "#616970")};
    }
  }
`;
