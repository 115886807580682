import React from "react";
import PropTypes from "prop-types";
import Box from "common/components/Box";
import Text from "common/components/Text";
import Heading from "common/components/Heading";
import Button from "common/components/Button";
import Container from "common/components/UI/Container";
import { useStaticQuery, graphql } from "gatsby";
import GatsbyImage from "common/components/GatsbyImage";
import guideSection from "../../images/guideSection.jpeg";

import PartnerSectionWrapper from "./partner.style";
import { maxHeight } from "styled-system";

const PartnerSection = ({
  row,
  col,
  title,
  description,
  button,
  textArea,
  imageArea,
  lang = "He",
}) => {
  const data = useStaticQuery(graphql`
    query {
      partner: file(relativePath: { eq: "image/saasClassic/partner.png" }) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
    }
  `);
  const text = {
    head: { En: "Create your Self-guided Tour", He: "התחל ליצור את הסיור שלך" },
    content: {
      En: "Do you have experience with tour-guiding? Do you have interesting content? Do you think you know your city the best? Would you like to make money out of it? Join the Dguides community, create your own tour through our platform and enjoy maximum exposure and an extra income!",
      He: "יש לכם נסיון בתחום הסיור וחושבים שהתוכן שלכם הוא הכי מעניין? חושבים שאתם מכירים את העיר שלכם הכי טוב ורוצים להרוויח מזה כסף? הצטרפו לקהילת DGUIDES, צרו את הסיור העצמאי שלכם בפלטפורמת יצירת הסיורים המובילה בעולם ותהנו מחשיפה מקסימלית והכנסה נוספת!",
    },
    buttonText: {
      En: "Join our guide community",
      He: "הצטרף לקהילת המדריכים שלנו",
    },
  };
  return (
    <PartnerSectionWrapper
      id="partner_section"
      style={{ direction: lang === "He" ? "rtl" : "ltr" }}
    >
      <Container>
        <Box {...row}>
          <Box {...col} {...imageArea}>
            <img
              src={guideSection}
              style={{
                borderRadius: "50% 35% 50% 35%",
                opacity: "65%",
                maxWidth: "280px",
              }}
            />
            {/* <GatsbyImage
              src={guideSectio
                (data.partner !== null) | undefined
                  ? data.partner.childImageSharp.gatsbyImageData
                  : {}
              }
              alt="partner"
            /> */}
          </Box>
          <Box {...col} {...textArea}>
            <Heading {...title} content={text["head"][lang]} />
            <Text {...description} content={text["content"][lang]} />
            <Box>
              <a href="https://d-guide.co/guideRegistration">
                <Button {...button} title={text["buttonText"][lang]} />
              </a>
            </Box>
          </Box>
        </Box>
      </Container>
    </PartnerSectionWrapper>
  );
};

PartnerSection.propTypes = {
  row: PropTypes.object,
  col: PropTypes.object,
  title: PropTypes.object,
  description: PropTypes.object,
  button: PropTypes.object,
  textArea: PropTypes.object,
  imageArea: PropTypes.object,
};

PartnerSection.defaultProps = {
  row: {
    flexBox: true,
    flexWrap: "wrap",
    ml: "-15px",
    mr: "-15px",
    alignItems: "center",
  },
  imageAreaRow: {
    flexDirection: "row-reverse",
  },
  col: {
    pr: "15px",
    pl: "15px",
  },
  textArea: {
    width: ["100%", "100%", "55%", "50%", "42%"],
  },
  imageArea: {
    width: ["100%", "100%", "45%", "50%", "58%"],
    mb: ["40px", "40px", "0", "0", "0"],
  },
  title: {
    fontSize: ["26px", "30px", "30px", "48px", "48px"],
    fontWeight: "400",
    color: "#fff",
    letterSpacing: "-0.025em",
    mb: "15px",
    lineHeight: "1.25",
  },
  description: {
    fontSize: "16px",
    color: "#ffe2e2",
    lineHeight: "1.75",
    mb: "33px",
  },
  button: {
    type: "button",
    fontSize: "14px",
    fontWeight: "600",
    borderRadius: "4px",
    pl: "22px",
    pr: "22px",
    color: "#fffff",
    //colors: "secondaryWithBg",
    backgroundColor: "#1c2338",
    capacity: "35%",
    minWidth: "150px",
  },
};

export default PartnerSection;
